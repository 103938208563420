@import "variables.scss";
.pageLayoutBody {
  min-height: calc(100vh - 580px);
  /*overflow: hidden; //break position stick of children*/
}

@media (max-width: 600px) {
  .pageLayoutBody {
    min-height: unset;
  }
}

.bannerSticky {
  position: sticky;
  top: 80px;
  z-index: 999;
}
