@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.landingSection {
  padding: 10px 0 15px;

  .landingSectionTitle {
    font-size: 20px;
    line-height: 24px;
    color: $paper;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .landingList {
    list-style: none;
  }
}

@media (min-width: $breakpointSM) {
  .landingSection {
    .landingSectionTitle {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 15px;
    }
  }
}

@media (min-width: $breakpointMD) {
  .landingSection {
    padding: 30px 40px;

    .landingList {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 33.3%;
        display: flex;

        &:nth-child(3n + 2) {
          align-items: flex-start;
          justify-content: center;
        }

        &:nth-child(3n + 3) {
          justify-content: flex-end;
          padding-right: 0;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .landingSection {
    .landingList {
      li {
        width: 25%;

        &:nth-child(8n + 1),
        &:nth-child(8n + 5),
        &:nth-child(8n + 9) {
          justify-content: flex-start;
        }

        &:nth-child(8n + 2),
        &:nth-child(8n + 3),
        &:nth-child(8n + 6),
        &:nth-child(8n + 7) {
          justify-content: center;
          padding-right: 16px;
        }

        &:nth-child(8n + 4),
        &:nth-child(8n + 8) {
          padding-right: 0;
          justify-content: flex-end;
        }
      }
    }
  }
}
