@import "variables.scss";
.image {
  overflow: hidden;
}

.imgResponsive {
  position: relative;
}

.imgResponsiveContain {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  z-index: 1;
}

.imgResponsiveContain {
  object-fit: contain;
}

.imgResponsiveCover {
  object-fit: cover;
}
