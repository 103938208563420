@import "variables.scss";
@media (max-width: $breakpointMD) {
  .listingModal:global(.MuiPaper-root) {
    position: absolute;
    width: 100%;
    top: calc(100% - 280px);
    z-index: 2;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .listingModal.closed:global(.MuiPaper-root) {
    top: calc(100% - 60px);
  }
}
