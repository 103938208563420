@import "variables.scss";
.mapTurnOnOffBttnContainer {
  position: relative;
}

.mapTurnOnOffBttnInner {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: var(--zIndexAboveMapBttn);
}

.markerPinHovered {
  transform: scale(1.3);
}

.detailsMap {
  width: 100%;
  height: 100%;
}

.toggleMapBtn {
  display: flex;
  gap: 6px;
}
