@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.filtersContainer {
  padding: 8px 12px;
  width: 100%;
  background-color: $paper;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;


  .firstRow {
    color: $dark300;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 4px;
  }

  .secondRow {
    color: $grey800;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    &.applied {
      color: $dark300;
    }
  }

  .thirdRow {
    color: $grey800;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;

    &.applied {
      color: $grey750;
    }
  }
}
