@import "variables.scss";
.imageGridContainer {
  --imageHeightMinToKeep: 220px;
  --imageGridHeight: min(700px, calc(100vh - var(--imageHeightMinToKeep)));
  max-width: calc(100% + 2px);
  margin-left: -2px;
  overflow: hidden;
}

.imageGridDetailsGrid {
  width: 100%;
  display: grid;
  grid-template: ". main second second" calc(var(--imageGridHeight) * 0.6) ". main third fourth" / 0px 60% 20% 20%;
  gap: 4px;
  overflow: hidden;
}

.imageGridDetailsGrid1Image {
  display: block;
  max-height: var(--imageGridHeight);
  height: calc(100vh * 0.4);
}

.imageGridDetailsGrid3Images {
  grid-template: ". main second second" calc(var(--imageGridHeight) * 0.6) ". main third third" / 0px 60% 20% 20%;
}

.imageGridDetailsGridMain {
  grid-area: main;
  overflow: hidden;
  height: 100%;
}

.imageGridDetailsGridMain > div {
  height: inherit !important;
}

.imageGridDetailsGridMain > div > div:first-child {
  height: inherit !important;
}

.imageGridDetailsGridSecond {
  grid-area: second;
  overflow: hidden;
}

.imageGridDetailsGridThird {
  grid-area: third;
  overflow: hidden;
}

.imageGridDetailsGridFourth {
  grid-area: fourth;
  overflow: hidden;
}

.imageGridImageMain {
  width: 100%;
  height: calc(var(--imageGridHeight) + 4px);
  cursor: pointer;
}

.imageGridImageSecond {
  width: 100%;
  height: calc(var(--imageGridHeight) * 0.6);
  cursor: pointer;
}

.imageGridImageSmall {
  width: 100%;
  height: calc(var(--imageGridHeight) * 0.4);
  cursor: pointer;
}

.imageGridDialogContainer {
  position: fixed;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  backdrop-filter: blur(10px);
  z-index: var(--zIndexImageGridOverlay);
  //padding-top: calc((100vh - var(--imageGridHeight)) / 2);
}

.imageGridDialogExit {
  position: fixed;
  top: 12px;
  right: 12px;
  height: 35px;
  width: 35px;
  cursor: pointer;
  z-index: var(--zIndexImageGridCloseIcon);
}

.imageGridDialogCarousel {
  height: 100vh; //var(--imageGridHeight) !important;
  background: #000;
  overflow: hidden;
}

.imageGridDialogCarousel > div > div {
  height: 100vh !important;
}

.imageGridCount {
  position: relative;
}

.imageGridDialogImage {
  height: 100vh;
}

.imageGridCountInner {
  position: absolute;
  top: -72px;
  right: 12px;
  z-index: 6;
  width: 120px;
  height: 60px;
  backdrop-filter: blur(60px);
  background-color: rgba(0, 0, 0, 0.18);
  border: 1px solid var(--common-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media (max-width: $breakpointMD) {
  .imageGridContainer {
    --imageGridHeight: min(500px, calc(100vh - var(--imageHeightMinToKeep)));
    margin-left: -3px;
  }

  .imageGridDetailsGrid2Images {
    grid-template: ". main main" var(--imageGridHeight) ". second second" / 0px 50% 50%;
    height: calc(100vh * 0.4);
  }
}

@media (max-width: $breakpointSM) {
  .imageGridContainer {
    --imageGridHeight: min(225px, calc(100vh - var(--imageHeightMinToKeep)));
    margin-left: 0px;
    max-width: 100%;
  }

  .imageGridDetailsGrid {
    grid-template: ". main main" var(--imageGridHeight) ". second third" / 0px 50% 50%;
    gap: unset;
    row-gap: 4px;
  }

  .imageGridDetailsGrid1Image {
    --imageGridHeight: min(250px, calc(100vh - var(--imageHeightMinToKeep)));
    display: block;
    height: calc(100vh * 0.4);
    max-height: var(--imageGridHeight);
    height: var(--imageGridHeight);
    overflow: hidden;
  }

  .imageGridImageMain {
    height: 36vh;
    width: 100%;
  }

  .imageGridImageSecond,
  .imageGridImageSmall {
    height: 100px;
    width: calc(100% - 2px);
  }

  .imageGridDetailsGridSecond {
    display: flex;
    justify-content: flex-start;
  }

  .imageGridDetailsGridThird {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-height: 450px) {
  .imageGridDialogContainer {
    padding-top: unset;
  }

  .imageGridDialogCarousel {
    height: 100vh !important;
  }

  .imageGridImageMain {
    height: 100vh !important;
    width: 100%;
  }
}
