@import "variables.scss";
.resetPasswordForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.resetPasswordFormBttnInner {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.buttonLink {
  width: 100%;
  text-decoration: none;
}
