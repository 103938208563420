@import "variables.scss";
.exitBttnContainer {
  position: relative;
}

.exitBttnInner {
  position: absolute;
  top: 0px;
  right: 0px;
  color: var(--common-black);
}
