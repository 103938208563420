@import "variables.scss";
.signUpFormEmail {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.signUpFormBttnInner {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.buttonLink {
  width: 100%;
  text-decoration: none;
}

.signUpFormCheckbox {
  text-align: left;
}

@media (max-width: $breakpointSM) {
  .signUpFormEmail {
    gap: 12px;
  }
}
