@import "variables.scss";
.container {
  background-color: transparent;
}

.toggleButtonCommon {
  display: flex;
  gap: 6px;
  width: 140px;
}

.bgColorSelectedViewVariantMap {
  &:global(.Mui-selected) {
    background-color: var(--common-white);

    &:hover {
      background-color: var(--common-white);
    }
  }
}

.bgColorViewVariantFull {
  background-color: transparent;
}

.bgColorUnselectedVariantMap {
  &:global(.Mui-selected) {
    background-color: var(--primary-dark);

    &:hover {
      background-color: var(--primary-dark);
    }
  }
}

.colorSelectedViewVariantMap {
  &:global(.Mui-selected) {
    border-color: var(--common-grey-800);
    color: var(--common-grey-800);
  }
}

.colorSelectedViewVariantFull {
  border-color: var(--common-white);
  color: var(--common-white);
}

.colorUnselectedViewVariantMap {
  &:global(.Mui-selected) {
    border-color: var(--common-white);
    color: var(--common-white);
  }
}

.colorUnselectedViewVariantFull {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}
