@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.baseModal {
  & :global .MuiPaper-root {
    padding: 24px;
    min-width: 420px;
    max-width: 600px;
  }
}

.dialogWithExitBtnContainer {
  position: relative;
  z-index: 1;
}

.dialogWithExitExitBtnInner {
  position: absolute;
  top: -8px;
  right: -8px;
  color: var(--common-black);
  z-index: 999;
}

.dragBarContainer {
  margin-bottom: 10px;
}

@media (max-width: $breakpointMD) {
  .baseModal {
    & :global .MuiPaper-root {
      padding: 16px;
    }

    & :global .MuiDialog-container {
      align-items: flex-end;
    }
    & :global .MuiDialog-paper {
      max-width: 100%;
      min-width: unset;
      width: 100%;
      margin: 0px;
    }
  }
}
