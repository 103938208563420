@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.footerContactInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: -4px;
  margin-bottom: 30px;
}

@media (min-width: $breakpointMD) {
  .footerContactInfo {
    margin-top: 30px;
  }
}
