@import "variables.scss";
.listGridVariant {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  width: 100%;
  margin: 0 auto 40px;
  height: unset;
  padding: 20px 16px 40px;
  background: var(--background);
  list-style-type: none; 
}

.listGridVariant:global(.map) {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  height: 100%;
  overflow-y: scroll;
  padding: 12px 16px 60px;
  background: var(--background);
}

.listGridVariant:global(.map)::-webkit-scrollbar {
  display: none;
}

.listGridVariant:last-child {
  margin-bottom: 0;
}

.listingGridAdd {
  width: calc(200% + 20px);
  overflow: visible;
}

.listingGridEmpty {
  position: relative;
  z-index: -1;
}

.listingGridAdd:global(.map) {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

.listingGridEmpty:global(.map) {
  display: none;
}

@media (max-width: $breakpointXL) {
  .listGridVariant {
    padding: 20px 16px;
    grid-template-columns: repeat(4, 1fr);
  }

  .listGridVariant:global(.map) {
    overflow-y: scroll;
    padding: 12px 16px 0px;
    background: var(--background);
  }
}

@media (max-width: $breakpointLG) {
  .listGridVariant {
    grid-template-columns: repeat(3, 1fr);
  }

  .listingGridAdd {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }

  .listingGridEmpty {
    display: none;
  }
}
@media (min-width: $breakpointMD) {
  .listGridVarianUpSell {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: $breakpointMD) {
  .listGridVariant:global(.map) {
    display: grid;
    height: unset;
    overflow-y: unset;
    padding: 20px 16px;
  }

  .listGridVariant {
    padding: 20px 16px;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 0px;
  }
}

@media (max-width: $breakpointSM) {
  .listGridVariant,
  .listGridVariant:global(.map) {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px 16px; 
  }
}
