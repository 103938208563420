@import "variables.scss";
.detailSectionInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0px 24px;
  border-bottom: 1px solid var(--grey-300);
}

.detailSectionInfoBodyIconContainer {
  display: flex;
  gap: 6px;
}

.iconDefaults {
  fill: var(--grey-500);
  color: var(--common-black);
}

.lineThrough {
  text-decoration: line-through;
  text-decoration-color: var(--grey-500);
  color: var(--grey-500);
  fill: var(--grey-500);
}

.childrenText {
  white-space: pre-wrap;
}

.diabledColor {
  color: var(--grey-500);
  fill: var(--grey-500);
}

/**
 * similiar carousel styles
 */

.detailSimiliarSectionInfo {
  height: 440px;
}

.detailSimiliarSectionInfo > section {
  border: none !important;
}

.detailSimiliarCarousel {
  margin-top: 12px;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100vw;
  height: 400px;
  z-index: 1;
  cursor: pointer;
}

.detailSimiliarCarouselSwiper {
  padding-left: calc((100% - $breakpointLG) / 2) !important;
  padding-right: calc(((100% - $breakpointLG) / 2) + 404px) !important;
  height: 344px;
}

.detailSimiliarCarouselButtonsContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: -44px;
}

.detailSimiliarCarouselEmptySlot {
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
}

@media (max-width: $breakpointLG) {
  .detailSimiliarCarouselSwiper {
    padding-left: 24px !important;
    padding-right: unset !important;
  }
}

@media (max-width: $breakpointMD) {
  .detailSimiliarCarouselSwiper {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

@media (max-width: $breakpointSM) {
  .detailSimiliarCarouselSwiper {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
