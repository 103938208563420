@import "variables.scss";
.contactCaptureContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 520px;
  max-width: 100vw;
}

.contactCaptureTitle {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.contactCaptureEmail {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contactCaptureBlaceLogo {
  width: 100%;
  display: flex;
  justify-content: center;
}

.contactCaptureBttnInner {
  display: flex;
  justify-content: center;
  gap: 12px;
}

@media (max-width: $breakpointSM) {
  .contactCaptureContainer {
    gap: 20px;
    max-width: 100%;
  }
}
