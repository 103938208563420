@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.error {
  color: $error700;
}

.success {
  color: $success300;
}
