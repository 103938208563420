@import "variables.scss";
.container {
  background-color: transparent;
}

.toggleButtonCommon {
  display: flex;
  gap: 6px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  color: var(--common-black);

  &:global(.Mui-selected) {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }
  }
}

.borderBottomWhite {
  border-color: var(--common-white);
}

.borderBottomBlack {
  border-color: var(--common-black);
}

.borderBottomDark {
  border-color: var(--grey-500);
}

.textColorSelected {
  color: var(--common-white);

  &:global(.Mui-selected) {
    color: var(--common-white);

    &:hover {
      color: var(--common-white);
    }
  }
}

.textColorUnSelected {
  color: var(--grey-500);

  &:global(.Mui-selected) {
    color: var(--common-black);
  }
}
