@import "variables.scss";
.listingCarouselIndicatorContainer {
  position: relative;
  top: -50px;
  z-index: 2;
}

.listingCarouselDoNotShow {
  display: none;
}

.carouselImageNavButton {
  backdrop-filter: blur(40px);
  background-color: rgba(0, 0, 0, 0.22);
}

.carousel > div > div > div > div > div {
  height: 100%;
}
