@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.copyButton {
  color: $secondaryMain;
  padding: 0;
  font-size: inherit;
  min-width: auto;

  span {
    margin-right: 0;
  }
}
