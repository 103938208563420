@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.footerSignup {
  grid-area: signup;
  background: var(
    --gradient,
    linear-gradient(
      90deg,
      rgba(95, 152, 240, 0.7) 4.69%,
      rgba(46, 84, 235, 0.7) 83.33%
    ),
    var(--secondary-main)
  );
  border-radius: var(--borderRadiusSmall);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footerSignupTitle > * {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}

.footerSignupDescription > p {
  max-height: 45px;
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}

.footerSignupInputContainer {
  display: flex;
  justify-content: space-between;
}

.footerSignupInput {
  background: transparent !important;
  border-radius: var(--borderRadiusSmall) 0px 0px var(--borderRadiusSmall);
  padding: 21px 24px;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

.footerSignupInputWhite {
  color: $paper;
  border: 1px solid $paper;
}

.footerSignupInputError {
  color: var(--error-dark);
  border: 1px solid var(--error-dark);
}

.footerSignupInput::placeholder {
  color: $paper;
  opacity: 1;
}

.footerSignupInputMessage {
  padding: 4px 0 0 24px;
}

.footerSignupBttn {
  background: $paper;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 0px 2px 2px 0px;
  border: 0px;
  font-size: 22px;
  width: 66px;
}

@media (max-width: 840px) {
  .footerSignupDescription > p {
    font-size: 14px;
    white-space: wrap;
  }

  .footerSignupTitle > * {
    overflow: unset !important;
    display: unset !important;
    -webkit-line-clamp: unset !important;
    line-clamp: unset !important;
    -webkit-box-orient: unset !important;
  }

  .footerSignupDescription > p {
    max-height: 45px;
    overflow: unset !important;
    display: unset !important;
    -webkit-line-clamp: unset !important;
    line-clamp: unset !important;
    -webkit-box-orient: unset !important;
  }
}
