@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.extraFiltersModal {
  & :global .MuiPaper-root {
    max-width: 645px;
  }
}
.modalTitle {
  color: $primary900;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding-bottom: 24px;
}

.titleDivider {
  margin: 0 -24px;
  border-color: $grey150;
}

.modalContent {
  overflow-y: scroll;
  padding: 16px 0 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .switchExclusive {
    & :global .MuiSwitch-switchBase.Mui-checked {
      color: $secondary500;
      &:hover {
        background-color: alpha($secondary500);
      }
    }

    & :global .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
      background-color: $secondary500;
    }
  }

  .exclusiveTag {
    color: $dark300;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid transparent;
    background-clip: content-box;
    background-color: $grey150;
  }
}

.actionButtons {
  width: 100%;
  display: flex;
  gap: 8px;
  padding-top: 16px;

  .actionButton {
    width: 100%;
    height: 40px;
    padding: 14px 10px;
    text-align: center;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }

  .clear {
    color: $grey800;
    background-color: $grey150;
  }

  .apply {
    background-color: $secondary500;
    color: $paper;
  }
}

@media (max-width: $breakpointMD) {
  .modalContent {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .titleDivider {
    margin: 0 -16px;
  }
}
