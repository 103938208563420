@import "variables.scss";
.pageLoadingLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40%;
}

@media (max-width: $breakpointMD) {
  .pageLoadingLayout {
    min-height: calc(100vh - 336px);
  }
}
