@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.quickFiltersContainer {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  padding: 12px 12px 12px 0;

  transition-property: background-color, width, height;
  transition-duration: 0.7s;
  transition-timing-function: ease-in;

  & :global .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-width: 1px;
    border-color: $secondary250 !important;
  }
}

.inputCommonStyle {
  font-size: 12px;
  padding: 15.5px 14px;
  padding-left: 4px;
  color: $dark300;

  &::placeholder {
    color: $grey800;
    font-size: 12px;
    color: $grey800;
    opacity: 1;
  }
}

.eventSelect {
  width: 100%;

  &:global(.MuiAutocomplete-root .MuiOutlinedInput-root) {
    padding: 0;
    padding-left: 14px;

    input {
      @extend .inputCommonStyle;
    }

    &:focus-visible {
      outline: none;
    }
  }
}

.vendorTypeSelect {
  width: 100%;
}

.filtersSelect {
  height: 48px;
}

.guestCount {
  input {
    @extend .inputCommonStyle;
  }

  &:focus-visible {
    outline: none;
  }
}

.extraFiltersWrapper {
  display: flex;
  align-items: center;
  padding: 14px 24px;
  background-color: $grey150;
  cursor: pointer;
  color: $grey800;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.guestCount,
.citySelect,
.pricingSelect {
  width: calc(50% - 6px);
}

.searchButton {
  display: block;
  width: 100%;
  background-color: $secondary500;
  color: $paper;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 12px;

  &:hover {
    background-color: darken($color: $secondary500, $amount: 4);
  }
}

@media (min-width: $breakpointMD) {
  .quickFiltersContainer {
    display: flex;
    gap: 12px;
    flex-wrap: nowrap;
    padding: 0;
  }

  .extraFiltersWrapper {
    display: flex;
  }

  .eventSelect {
    width: 40%;
  }

  .citySelect {
    width: 30%;
  }

  .guestCount {
    width: 30%;
  }

  .searchButton {
    display: none;
  }

  .filtersSelect {
    width: 30%;
  }
}

@media (max-width: $breakpointMD) {
  .extraFiltersWrapper {
    display: none;
  }
}
