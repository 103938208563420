@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.sliderText {
  color: var(--Light-Grey-Body-Typography-Body, $grey800);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.sliderValue {
  color: $primary900;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.sliderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider {
  & :global .MuiSlider-thumb[data-index="0"] {
    margin-left: 8px;
  }
  & :global .MuiSlider-thumb[data-index="1"] {
    margin-left: -8px;
  }
}
