@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.footerSocialButton {
  background: transparent;
  text-decoration: none;
  padding: 8px;
  border: 1px solid $paper;
  border-radius: var(--borderRadiusSmall);
  margin-right: 12px;

  .footerSocialButtonIcon {
    width: 24px;
    height: 24px;
  }
}

@media (min-width: $breakpointMD) {
  .footerSocialButton {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
  }
}
