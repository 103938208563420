@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.detailContainer {
  padding: var(--paddingSmall);
  display: flex;
  flex-direction: column;
}

.detailContainerImageGrid {
  max-width: 100vw;
  overflow: hidden;
}

.detailInner {
  max-width: $breakpointLG;
  width: $breakpointLG;
  margin: 0px auto;
  padding-top: 24px;
  display: flex;
}

.detailInnerLeft {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: calc(100% - 380px);
  padding-right: 24px;
}

.detailInnerRight {
  width: 380px;
}

.detailInnerBottom {
  height: fit-content;
  margin: 0 12px;
  width: calc(100% - 12px);
}

@media (min-width: calc($breakpointMD + 1px)) {
  .detailInnerBottom {
    width: calc(100% - 415px)
  }
}

@media (min-width: calc($breakpointLG + 1px)) {
  .detailInnerBottom {
    margin: 0 45px;
    max-width: 985px;
  }
}

.detailInquiryContainer {
  position: sticky;
  top: 105px;
  border: 1px solid var(--grey-300);
  border-radius: var(--borderRadiusSmall);
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 116px);
  background-color: var(--common-white);
  z-index: 5;
}

.detailInquiryContainer::-webkit-scrollbar {
  display: none;
}

.detailInquiryContainerScaleDown {
  transform: scale(0.86);
  top: 44px;
  max-height: unset;
}

.detailFooter {
  display: none;
}

.detailFooterInner {
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: var(--common-white);
  z-index: var(--zIndexDetailInquiryFooter);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 16px;
  -webkit-transition: height 0.2s ease;
  -moz-transition: height 0.2s ease;
  -o-transition: height 0.2s ease;
  transition: height 0.2s ease;
}

.detailFooterInnerActive {
  height: 112px;
}

.detailFooterInnerHidden {
  height: 0px;
}

.detailFooterFormExit {
  top: 12px;
  right: 12px;
  z-index: 10;
}

@media (max-width: $breakpointLG) {
  .detailInner {
    width: unset;
    max-width: unset;
    margin: 12px 12px 0 12px;
  }
}

@media (max-width: $breakpointMD) {
  .detailInner {
    display: unset;
    height: unset;
    padding-top: unset;
  }

  .detailInnerLeft {
    width: unset;
    padding-right: unset;
  }

  .detailInnerRight {
    display: none;
  }

  .detailFooter {
    display: block;
  }

  .detailInquiryContainerScaleDown {
    transform: unset;
  }
}

@media (max-width: $breakpointSM) {
  .detailContainer {
    padding: unset;
  }

  .detailInner {
    margin: 12px 12px 12px;
  }
}
