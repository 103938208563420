@import "variables.scss";
@import "@/styles/scss/theme.module.scss";

.headerSticky {
  position: sticky;
  top: 0;
  z-index: var(--zIndexNavigationBarDesktop);
}

.header {
  height: 80px;
  padding: 0 0px 0px 20px;
  background: var(--primary-dark);
  display: flex;
  justify-content: space-between;
  gap: 0px;
  transition: all 0.3s ease;

  @media (max-width: $breakpointSM) {
    background-color: $grey850;
  }

  .headerNavContainer {
    width: 100%;
    min-width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 0;
    transition: all 0.3s ease;
  }
}

.headerNavTransparent {
  background: transparent !important;
}

.headerNav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
  height: 100%;
  width: calc(100% - 81px);
  overflow-x: scroll;
}

@media (max-width: 1300px) {
  .headerNav {
    gap: 40px;
  }
}

.headerNav::-webkit-scrollbar {
  display: none;
}

.headerNavMobileLogo {
  display: none;
}

.headerSearchInput {
  min-width: 600px;
  /*20px = padding left, 120px = blace logo, 80px = open hamburger*/
  margin-left: calc((100% - (600px + 80px + 120px + 20px)) / 2);
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 1200px) {
    min-width: 450px;
  }
}

.navBarDesktopOptsDrawerBtn {
  width: 80px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navBarDesktopOptsDrawerBtnInner {
  cursor: pointer;
  border-radius: var(--borderRadiusSmall);
  padding: 9px 7px;
}

.listingQuickFiltersContainer {
  display: none;
}

.extraFiltersWrapper {
  display: none;
}

@media (max-width: $breakpointMD) {
  .header {
    padding: 0px 0px 0px 12px;
    max-width: 100%;
    overflow: hidden;

    &.listingQuickFiltersOpen {
      flex-direction: column;
      height: unset;
      transition: height 0.3s ease;
      z-index: 1000;

      .headerNavContainer {
        padding-top: 12px;
      }
    }
  }

  .headerNav {
    width: calc(100% - 61px);
    gap: 12px;
    -webkit-transition: width 0.3s ease;
    -moz-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }

  .extraFiltersWrapper {
    display: flex;
    align-items: center;
    padding: 14px 24px;
    cursor: pointer;
    position: relative;

    .filtersCount {
      position: absolute;
      color: $secondary500;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      border-radius: 100px;
      background: $secondary270;
      right: 7px;
      padding: 2px 8px;
      top: 14px;
    }
  }

  .navBarDesktopOptsDrawerBtn {
    width: 60px !important;
    padding-right: 4px;
  }

  .headerNav:global(.focused) {
    width: calc(100% - 18px);
  }

  .headerSearchInput {
    width: 100%;
    min-width: unset;
    margin-left: unset;
  }

  .listingQuickFiltersContainer {
    display: none;
    transition: display 0.3s ease;

    &.visible {
      display: block;
      transition: display 0.3s ease;
    }
  }

  .headerNavDesktopLogo {
    display: none;
  }

  .headerNavMobileLogo {
    display: block;
    margin-top: 6px;
  }
}

.drawerScrollbar {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--grey-400);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
}

.contactMailWrapper {
  margin: 0 auto;

  @media (max-width: $breakpointMD) {
    display: none;
  }
}

.textDecoration {
  text-decoration: none;
}

.contactMaillink {
  @extend .textDecoration;
}

.contactMailContent {
  display: flex;
  align-items: center;
}

.contactMailIconWrapper {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  background-color: var(--grey-800);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--common-white);
}

.contactMailTextWrapper {
  margin-left: 10px;
}

.contactQuestionText {
  font-weight: 400;
  color: var(--grey-500);
}

.contactQuestionTextWhite {
  color: var(--common-white);
}

.contactSendMailText {
  font-weight: 500;
  color: var(--common-white);
}

.drawerWrapper {
  padding: 20px 24px;

  .drawerDivider {
    border-color: var(--grey-500);
  }

  .title {
    text-transform: uppercase;
    color: var(--grey-500);
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    margin: 20px 0;
  }

  .link {
    @extend .textDecoration;

    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 20px;
    display: block;
    color: var(--common-white);
  }

  .accountLink {
    @extend .textDecoration;
  }

  .accountLink button {
    box-shadow: none !important;
  }

  .accountLinkContent {
    @extend .link;

    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .arrowOffsetRight {
    margin-right: 5px;
  }

  .pagesList {
    list-style: none;
    padding-left: 0;
  }
}

.notificationAlignUnread {
  margin-left: -2px;
}

.profileSectionWrapper {
  margin-bottom: 20px;

  .profileContent {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .avatarCommon {
    margin-right: 16px;
    border-radius: 50%;
  }

  .profileAvatar {
    @extend .avatarCommon;
  }

  .userInitial {
    @extend .avatarCommon;

    width: 60px;
    height: 60px;
    background-color: var(--grey-400);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .userInitialText {
    text-transform: uppercase;
    color: var(--common-white);
    font-weight: 600;
  }

  .profileInfo {
    color: var(--common-white);
  }

  .userName {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--common-white);
  }

  .memberYear {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    color: var(--common-white);
  }
}

.questionsWrapper {
  .contactQuestionText {
    font-size: 14px;
    font-weight: 600;
    color: var(--common-white);
  }

  .contactMailWrapper {
    margin: 15px 0 20px;
  }

  .contactMaillink {
    text-decoration: none;
  }

  .contactMaillink button {
    //box-shadow: none !important;
  }

  .contactButtonHolder {
    margin: 20px 0;
  }

  .contactButton {
    padding: 8px 22px;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    display: flex;
    gap: 6px;
    text-transform: none !important;
  }

  .contactIcon {
    margin-right: 12px;
  }
}

.eventsWrapper {
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 13px;
    background: var(--grey-400);
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .linkExpandWrapper {
    position: relative;
    cursor: pointer;
  }

  .expandIcon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .collapseWrapper {
    background-color: rgba(255, 255, 255, 0.2);
    padding: 12px;
    margin-bottom: 20px;
    overflow-y: scroll;
    max-height: 220px;
  }

  .paymentInfo {
    margin-bottom: 12px;
  }

  .paymentInfo:last-child {
    margin-bottom: 0px;
  }

  .paymentInfo a {
    text-decoration: none;
  }

  .paymentInfo a:hover {
    text-decoration: underline;
    text-decoration-color: var(--common-white);
  }

  .paymentTitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--common-white);
  }

  .inquiryLink {
    color: var(--grey-200);
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: underline;
  }

  .inquiryTime {
    color: var(--grey-400);
    font-size: 12px;
  }
}

.accountWrapper {
  margin-top: 20px;
}

.accountInner {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
}

.accountBttn {
  display: flex;
  gap: 6px;
}

@media (max-width: $breakpointMD) {
  .drawerWrapper {
    margin-bottom: 120px;
  }
}

.linkButton {
  text-decoration: none;
}
